import React from 'react';
import styled from 'styled-components'
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'


const Styles = styled.div`

`

function Footer(props) {

    const [, updateState] = React.useState();
    const [fixed, updateFixed] = React.useState(true);
    const forceUpdate = React.useCallback(() => updateState({}), []);

    return (
        <Styles>

{/* <svg className="fill-svg-drk-blue" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon className="fill-inverse" points="2560 0 2560 100 0 100"></polygon></svg> */}

            <Segment className="footerHome" inverted vertical style={{ padding: '5em 0em' }}>

                <Container>
                    <Grid divided inverted stackable>
                        <Grid.Row>
                            <Grid.Column width={4} style={{marginLeft:"auto"}}>
                                <Header inverted as='h4' content='More' />
                                <List link inverted>
                                    <List.Item as='a' href='/about'>About</List.Item>
                                    <List.Item as='a' href='/contact'>Contact</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content='Services' />
                                <List link inverted>
                                    <List.Item as='a' href='/#ecommerce'>E-commerce</List.Item>
                                    <List.Item as='a' href='/#finance'>Full Stack Solutions</List.Item>
                                    <List.Item as='a' href='/#games'>Engaging Projects</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={4} style={{marginRight:"auto"}}>
                                <p>
                                    Yay Fun, Inc.<br />
                                    3616 Far West Blvd 117-414<br />
                                    Austin, Tx. 78731
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>

        </Styles>)
}

export default Footer
