import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import Lottie from 'lottie-web';
import VisibilitySensor from 'react-visibility-sensor';


/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const blueColor = "#296aac";
const darkBlue = "#013153";

const urlPrefix = "http://localhost:3000";

function onChange(isVisible) {
    console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
}

const HomepageHeading = ({ mobile }) => (

    
    <Container
        style={{
            backgroundColor: blueColor,
            padding: '5em',
            paddingLeft: '1em',
            paddingRight: '1em'
        }}
        text>
        <Header
            as='h1'
            className="header1"
            content='Software solutions for your business.'
            inverted
            style={{
                fontSize: mobile ? '3em' : '3em',
                fontWeight: '500',
                marginBottom: 0,
                display: "inline-block",
                marginTop: mobile ? '0.5em' : '0.5em',
                border: "0px solid white",
                padding: "0em",
                paddingBottom: "0em",
                paddingLeft: "0em",
                paddingRight: "0em",
                lineHeight: "1em"
            }}
        />

        <Header
            as='h2'
            content='Over 12 years developing software solutions.'
            inverted
            style={{
                textAlign: "center",
                paddingTop: "0em",
                fontSize: mobile ? '1.2em' : '1.2em',
                fontWeight: '100',
                marginTop: mobile ? '0.5em' : '1.5em',
            }}
        />
        <Button size='huge' className="getStartedButton" style={{ marginTop: "5em", color: "white" }} onClick={() => {
            window.location = "/contact";
        }}>
            Contact Us
            <Icon name='right arrow' />
        </Button>
    </Container>
)

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {

    state = {}

    constructor(props) {
        super(props);

        this.myDivToFocus = React.createRef();
        this.addedEcomAnimaton = false;
    }


    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: false })

    componentDidMount() {

        Lottie.loadAnimation({
            container: document.getElementById('svg-ecommerce'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/ecommerce.json',
        });

        Lottie.loadAnimation({
            container: document.getElementById('svg-finance'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/finance.json',
        });

        Lottie.loadAnimation({
            container: document.getElementById('svg-sales'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/sales.json',
        });

        Lottie.loadAnimation({
            container: document.getElementById('svg-graphs'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/graphs.json',
        })

        Lottie.loadAnimation({
            container: document.getElementById('svg-manage'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/manage.json',
        })

        Lottie.loadAnimation({
            container: document.getElementById('svg-gaming'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: './data/gaming.json',
        });


    }

    render() {

        const { children } = this.props
        const { props } = this.props
        const { fixed } = this.state

        return (
            <Responsive >
                
                <Segment
                    inverted
                    textAlign='center'
                    style={{
                        padding: '0em 0em',
                        backgroundColor: blueColor
                    }}
                    vertical>
                    <HomepageHeading />
                    <svg className="fill-white-svg" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon></svg>
                </Segment>

                <Segment vertical style={{ paddingTop: "0em" }} className="gridSection">
                   
                {/* <Grid container stackable verticalAlign='middle'>
                        <Grid.Row id="ecommerce" style={{ paddingTop: "8em", paddingBottom: "1em" }}>


                            <Grid.Column width={8}>
                                <p className="gridContentHeader">How can we help your business?</p>
                                <p className="gridText">With over a decade of experience deploying software projects, we can help your company achieve your goals.</p>
                            </Grid.Column>

                            <Grid.Column width={8}>

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>Web Design & Development</Button>

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>Social Media Management</Button>                        

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>Full Stack Solutions</Button>

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>Custom API Integration</Button>                         

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>E-commerce Selling & Ads</Button> 

                            <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {

                            }}>Accounting Management - QuickBooks API</Button> 


                            </Grid.Column>
                        </Grid.Row>
                    </Grid> */}
            

                <Grid container stackable verticalAlign='middle'>
                    
                    
                        <Grid.Row id="ai" style={{ paddingTop: "80px", paddingBottom: "1em" }}>
                            
                            <Grid.Column width={8}>
                            <a href="https://www.socialbellow.com">
                                <img style={{borderRadius:"6px", width:"100%"}} src="/images/socialbellow-logo.jpg" alt="Social Bellow" />
                            </a>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <p className="gridContentHeader">Artificial Intelligence</p>
                                <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {
}}>Effortless Content Generation</Button>
 <Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {
}}>Diverse Content Types</Button>
<Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {
}}>Streamlined AI User Experience</Button>
<Button size='huge' className="typeButton" style={{ color: "#296aac" }} onClick={() => {
}}>Creative Inspiration</Button>    
                                <p className="gridTextSmall">Strengthen your brand identity and customer engagement with Social Bellow's AI-generated content. From crafting persuasive product descriptions and email newsletters to creating eye-catching taglines and captivating video scripts, our platform empowers you to connect with your target audience and showcase your products or services effectively. Grow your business with the power of AI-driven content creation at your fingertips.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                    <Grid container stackable verticalAlign='middle'>
                    <div className="divider" style={{ marginLeft: "auto", marginRight: "auto" }}></div>

                        <Grid.Row id="ecommerce" style={{ paddingTop: "2em", paddingBottom: "1em" }}>
                            <Grid.Column width={8}>
                                <p className="gridContentHeader">E-commerce</p>
                                <p className="gridText">Product publishing tools can help get your products online and selling <u><b>fast</b></u>.</p>
                                <p className="gridText">Our user interface tools make it easy to search, bulk edit, and publish your inventory to many places.</p>
                                <p className="gridText">Leverage our software platforms to sell <b><u>more</u></b> and maintain a healthy ROI by tracking every aspect of your online selling business.</p>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <div id='svg-ecommerce'></div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid container stackable verticalAlign='middle' >

                        <Grid.Row style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                            <Grid.Column floated='right' width={5}>
                                <div className="columnCard shadow-5">
                                    <div id='svg-sales'></div>
                                    <p className="columnHeader">Sell</p>
                                    <p className="columnText">Maintain and analyze a history of your sales to analyze and forecast. Use your own data to make smart decisions scouting for new products and forecasting future sales.</p>
                                </div>
                            </Grid.Column>

                            <Grid.Column floated='right' width={5}>
                                <div className="columnCard shadow-5">
                                    <div id='svg-graphs'></div>
                                    <p className="columnHeader">Analyze</p>
                                    <p className="columnText">Displaying the most relavant data to your organization is key to forecasting the future and taking action.</p>
                                </div>
                            </Grid.Column>

                            <Grid.Column floated='right' width={5}>
                                <div className="columnCard shadow-5">
                                    <div id='svg-manage'></div>
                                    <p className="columnHeader">Manage</p>
                                    <p className="columnText">We can help your company bring many API's together in one internal interface that is secure and unique to your company.</p>
                                </div>
                            </Grid.Column>

                        </Grid.Row>

                        <div className="divider" style={{ marginLeft: "auto", marginRight: "auto" }}></div>

                        <Grid.Row id="finance" style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                            <Grid.Column width={8}>
                                <div id='svg-finance'></div>
                            </Grid.Column>

                            <Grid.Column floated='right' width={7}>

                                <p className="gridContentHeader">Full Stack Solutions</p>
                                <p className="gridText">Build your entire project from the ground up, giving your company the ultimate flexibility.</p>
                                <p className="gridText">Tailor your processes in a single interface. </p>
                                <p className="gridText">Manage your data and provide group level or user specific access levels.</p>

                            </Grid.Column>
                        </Grid.Row>

                        <div className="divider" style={{ marginLeft: "auto", marginRight: "auto" }}></div>

                        <Grid.Row id="games" style={{ paddingTop: "2em", paddingBottom: "10em" }}>

                            <Grid.Column width={8}>
                                
                                <p className="gridContentHeader">Engaging Projects</p>                                
                                <p className="gridText">Work with us to provide an elevated user experience to your customers.</p>
                                <p className="gridText">Our extensive knowledge of game development are valuable resources your company can use to experiment new marketing strategies to engage your customers.</p>
                                

                            </Grid.Column>

                            <Grid.Column width={8}>
                                {/* <img className="" src="/images/yayfun-art-gaming.svg" /> */}
                                <div id='svg-gaming' />
                            </Grid.Column>


                        </Grid.Row>

                    </Grid>


                </Segment>

                <Segment style={{ padding: '0em' }} vertical>
                    <Grid celled='internally' columns='equal' stackable>
                        <Grid.Row textAlign='center' style={{ backgroundColor: "#2693ff", color: "white", fontWeight: "300", paddingLeft: "10%", paddingRight: "10%", paddingTop: "8em", paddingBottom: "10em" }}>
                            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '2em', paddingLeft: '3em', paddingRight: '3em' }}>

                                <img className="featureImage" src="/images/yayfun-art-design.svg" />
                                <Header as='h3' className="featureHeader">Design</Header>
                                <p className="featureText">
                                    Help your organization in a visually comprehensive user interface.
                                    We can help you focus on the most important aspects of your business.
                                    </p>
                            </Grid.Column>

                            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '2em', paddingLeft: '3em', paddingRight: '3em' }}>
                                <img className="featureImage" src="/images/yayfun-art-tech.svg" />
                                <Header as='h3' className="featureHeader">Technology</Header>
                                <p className="featureText">
                                    Our technology stack is hosted on Amazon Web Services (AWS) and can help take your business to the cloud. AWS is not affiliated with Yay Fun, Inc.
                                    </p>
                            </Grid.Column>

                            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '2em', paddingLeft: '3em', paddingRight: '3em' }}>

                                <img className="featureImage" src="/images/yayfun-art-confirm-work.svg" />
                                <Header as='h3' className="featureHeader">Custom Business Solutions</Header>
                                
                                <p className="featureText">
                                    Our services includes frictionless user identity and access management that scales.
                                    Each solution is tailored to your business' needs, built scalable and secure.
                                    </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

            </Responsive>
        )
    }
}





export default withRouter(DesktopContainer)