import React from 'react';
import Main from './components/Main';
import 'semantic-ui-css/semantic.min.css'

import './App.css';

import Amplify from 'aws-amplify';

import aws_exports from './aws-exports';

Amplify.configure(aws_exports);


function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
