import React from 'react';
import styled from 'styled-components'
import {
    Button,
    Container,
    Divider,
    Grid,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
  } from 'semantic-ui-react'

  
const Styles = styled.div`

`

function Header(props) {

    const [, updateState] = React.useState();
    const [fixed, updateFixed] = React.useState(true);
    const forceUpdate = React.useCallback(() => updateState({}), []);

return (
    <Styles>
        <Menu
              className="topBarMenu"
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                    
              <img src="/images/yayfun-bl.svg" 
                   style={{display:"inline-block", width:"60px", height:"60px", marginLeft:"10px", marginTop:"0px"}}/>
              
              <p style={{cursor:"pointer", fontSize:"1.2em", fontWeight:"200", paddingTop:"1.3em", whiteSpace:"nowrap"}} 
              onClick={()=>{
                window.location = "/";
              }}>Yay Fun, Inc.</p>

                <Menu.Item position='right' style={{paddingBottom:"0em", height:"100%"}}>
                    <span className="menuButton"onClick={()=>{
                        window.location = "/about";
                    }}>ABOUT</span>
                    <span className="menuButton" onClick={()=>{
                        window.location = "/contact";
                    }}>CONTACT</span>
                </Menu.Item>
                

              </Container>
            </Menu>
    </Styles>)
}

export default Header
