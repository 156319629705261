import React from 'react';
import styled from 'styled-components'
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'


const blueColor = "#296aac";
const darkBlue = "#013153";

const Styles = styled.div`

`

function Blog(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    return (
        <Styles>

            <div>

                <Segment vertical style={{ padding: "0em" }}>

                    <div className="parallax">

                        <Grid container stackable verticalAlign='middle'>

                            <Grid.Row style={{ paddingTop: "10em", paddingBottom: "50%", paddingLeft: "2em", paddingRight: "2em" }}>
                                <div style={{ padding: "4em", paddingTop: "2em", maxWidth: "40em", backgroundColor: "#fff", borderRadius: "1em" }}>
                                    <h1>Blog</h1>
                                    <h3>Current topic discussion covering the retail, finance, and game industry </h3>
                                    
                                </div>
                                <Grid.Column floated='right' width={5}></Grid.Column>
                            </Grid.Row>

                        </Grid>
                        <svg className="fill-svg-drk-blue" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <polygon className="fill-inverse" points="2560 0 2560 100 0 100"></polygon></svg>

                    </div>

                </Segment>

            </div>

        </Styles>
    )

}

export default Blog
