import React from 'react';
import { API }     from 'aws-amplify';
import styled from 'styled-components';
import CustomTextField from './CustomTextField';

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
  } from 'semantic-ui-react'

  
  const blueColor = "#296aac";
  const darkBlue = "#013153";
 
const Styles = styled.div`

`


function Contact(props) {

    const [, updateState] = React.useState();
    const [formData, setFormData] = React.useState({
        name:"",
        email:"",
        phone:"",
        message:""
    });
    
    const [inputError, setInputError] = React.useState({name:false, email:false, phone:false, message:false});

    const [loading, setLoading] = React.useState(false);

    const forceUpdate = React.useCallback(() => updateState({}), []);

    function validateFormData() {

        var Namere = /[A-Za-z]{1}[A-Za-z]/;
        if (!Namere.test(formData.name)) {
            setInputError({...inputError, name: true});            
            return false;
        }
        var mobilere = /[0-9]{10}/;
        if (!mobilere.test(formData.phone)) {
            setInputError({...inputError, phone: true});            
            return false;
        }
        if (formData.email=="") {
            setInputError({...inputError, email: true});            
            return false;
        }    
        var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (!reeamil.test(formData.email)) {
            setInputError({...inputError, email: true});        
            return false;
        }    

        return true;
    }


return (
    <Styles>

         <div>
         <Segment vertical style={{paddingTop:"0em"}}>

 <Grid container stackable verticalAlign='middle'>      

 <Grid.Row style={{paddingLeft:"", paddingTop:"8em", paddingBottom:"8em"}}>

      <Grid.Column width={5}>

      <h1>Contact</h1>

        <h2>Fill out the contact form and we will contact you.</h2>


        <CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"20em"}}              
                    properties={
                    {
                        name:"Name",
                        error:inputError.name,
                        rows:1
                    }
                    }
                    defaultValue={formData.name}
                    inputProps={{
                        'aria-label': 'Seller ID',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter Seller ID");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setFormData({...formData, name:e.target.value});
                        if (e.target.value) {
                            setInputError({...inputError, name:false});
                        }
                    }
                }/>   


<CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"20em"}}              
                    properties={
                    {
                        name:"Email",
                        error:inputError.email,
                        rows:1
                    }
                    }
                    defaultValue={formData.email}
                    inputProps={{
                        'aria-label': 'email',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter email");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setFormData({...formData, email:e.target.value});
                        if (e.target.value) {
                            setInputError({...inputError, email:false});
                        }
                    }
                }/>   



<CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"20em"}}              
                    properties={
                    {
                        name:"Phone",
                        error:inputError.phone,
                        rows:1
                    }
                    }
                    defaultValue={formData.phone}
                    inputProps={{
                        'aria-label': 'phone',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter phone");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setFormData({...formData, phone:e.target.value});
                        if (e.target.value) {
                            setInputError({...inputError, phone:false});
                        }
                    }
                }/>  


            <CustomTextField   
                    className="search-input"
                    style={{float:"left", minWidth:"20em", minHeight:"30em"}}              
                    properties={
                    {
                        name:"Message",
                        error:inputError.message,
                        rows:10
                    }
                    }
                    defaultValue={formData.message}
                    inputProps={{
                        'aria-label': 'message',
                    }}
                    onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        console.log("Key enter message");
                    }
                }}
                onChange= {            
                    e =>
                    { 
                        setFormData({...formData, message:e.target.value});
                        if (e.target.value) {
                            setInputError({...inputError, message:false});
                        }
                    }
                }/>  

        <form id="contact-form" method="post">
        
        <div data-sitekey="" className="form-control" style={{width:"100%"}}></div>
        <Button primary loading={loading} type="button" className="btn btn-lg" style={{marginTop:"35px"}} 
        onClick={async ()=>{
            if (!loading) {
                setLoading(true);
                if (validateFormData()) {
                    let params = {body:{...formData}};
                    const apiResponse = await API.post('Contact' , '/app/submit', params);    
                    setLoading(false);
                    if (apiResponse)
                    {      
                        setFormData({
                            name:"",
                            email:"",
                            phone:"",
                            message:""
                        });
                    }            
                } else {
                    setLoading(false);
                }
            }
        }} >Submit</Button>
        </form>
    
      </Grid.Column>
  </Grid.Row>
</Grid>

<svg className="fill-svg-drk-blue" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon className="fill-inverse" points="2560 0 2560 100 0 100"></polygon></svg>

</Segment>


         </div>

    </Styles>
)


}

export default Contact
