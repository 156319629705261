/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "Contact",
            "endpoint": "https://xjybullor6.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "yayfuninc-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2iqhq3rsid172.cloudfront.net"
};


export default awsmobile;
