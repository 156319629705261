import React from 'react';
import styled from 'styled-components'
import Header from './Header';

const Styles = styled.div`
    padding:0px
`

function Terms(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
return (
    <Styles>
        
        <Header/>
        
		<section class="content" id="legal">
		
        	<div class="container">
                            
                <h1>Terms of Use</h1>

                <h4>Introduction</h4>
                <p>Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using Inventory Shark, https://inventoryshark.io, https://app.inventoryshark.io, (the “Service”) operated by Yay Fun, Inc. (Yay Fun", "Yay Fun, Inc.", "us", "we", or "our"). Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service. </p>

                <h4>Subscriptions</h4>
                <p>
                    Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription. 
                    At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or we cancel it. You may cancel your Subscription renewal either through your online account management page or by contacting our customer support team. 
                    A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide us with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Yay Fun, Inc. to charge all Subscription fees incurred through your account to any such payment instruments. 
                    Should automatic billing fail to occur for any reason, We will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
                </p>
                
                <h4>Accounts</h4>
                <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service. 
                    You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                </p>         
                

                <h4>User Conduct.</h4>
<p> You agree to comply with all applicable laws, statutes, ordinances and regulations related to use of the Services. You may not use our Services to engage in fraud or other illegal activity, or to infringe the intellectual property rights of others.

You agree to comply with the terms and conditions of all agreements you have with third parties, including providers of drop ship Inventory, as such terms and conditions relate to use of the Services including, without limitation, terms and conditions related to types of items that may be sold or restrictions on concurrent sales.

You agree that you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the Site or any transaction conducted on the Site. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on our infrastructure or limits our ability to provide the Services. You agree that you will not collect, copy, reproduce, alter, modify, create derivative works, or publicly display any content or information (except for Your Materials) from the Site without the prior expressed written permission of Inventory Shark or the appropriate third party. You agree that you will not use any robot, spider, or other automatic device or routine to access or interface with the Site.</p>

<h4>Inventory Shark and Third-Party Intellectual Property.</h4>
<p>All content included or available on the Site, such as advertisements, text, graphics, logos, button icons, images, audio clips and software, is the property of Inventory Shark and/or third parties and is protected by copyrights, trademarks or other intellectual and proprietary rights. The compilation (meaning the collection, arrangement and assembly) of all content on the Site is the exclusive property of Inventory Shark and/or its licensors, suppliers and distributors and is protected by copyrights or other intellectual and proprietary rights. All software used on the Site is the property of Inventory Shark and/or its software suppliers or distributors and is protected by copyrights, patents or other intellectual and proprietary rights.

    Subject to the terms and conditions of this Agreement, Inventory Shark grants a limited license to you to make personal use of the Site and the Services only for their intended purposes. This license expressly excludes any resale of the Site and the Services, making any derivative of the Site or the Services, the collection and use of other users’ email addresses or other materials (for example, advertisements), or any data extraction or data mining whatsoever, except with the express consent of the owner of such materials.

    Inventory Shark retains all right, title, and interest in and to its trademarks, service marks and trade names worldwide (collectively, the “Marks”). You shall only use Inventory Shark's Marks with the express permission of Inventory Shark and then only in the manner authorized. Under no circumstances may you alter, modify, or change Inventory Shark's Marks.</p>


    <h4>Warranty Disclaimer.</h4>
<p>THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. INVENTORY SHARK AND OUR SUPPLIERS OR DISTRIBUTORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY. INVENTORY SHARK AND OUR SUPPLIERS OR DISTRIBUTORS SPECIFICALLY DISCLAIM ALL WARRANTIES INCLUDING WITHOUT LIMITATION:</p>

<p>(A) THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT;</p>

<p>(B) THAT THE SITE OR THE SERVICES WILL MEET YOUR REQUIREMENTS, WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE OR OPERATE WITHOUT ERROR;</p>

<p>(C) ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE; AND</p>

<p>(D) ANY OBLIGATION, LIABILITY, RIGHT, CLAIM OR REMEDY IN TORT, WHETHER OR NOT ARISING FROM THE NEGLIGENCE OF INVENTORY SHARK OR OUR SUPPLIERS OR DISTRIBUTORS.</p>

<p>Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not fully apply to you. You may also have other legal rights which vary from state to state.</p>

                <h4>Termination</h4>

                <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                If you wish to terminate your account, you may simply discontinue using the Service.
                All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                </p>

                <h4>Indemnification</h4>
                <p>You agree to defend, indemnify and hold harmless Yay Fun, Inc. and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>

                <h4>Limitation Of Liability</h4>
                <p>In no event shall Yay Fun, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h4>Disclaimer</h4>

                <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.

                Yay Fun its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                <h4>Exclusions</h4>
                <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>

                <h4>Governing Law</h4>
                    <p>These Terms shall be governed and construed in accordance with the laws of Texas, United States, without regard to its conflict of law provisions.
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>

                <h4>Changes</h4>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>
            
            <div style={{height:"100px"}}>&nbsp;</div>
            </div>
    </section>

    </Styles>
)


}

export default Terms
