import React, { Component } from 'react';

import styled from 'styled-components'
import {withRouter, Route, Switch } from 'react-router-dom';

//Page components
import HomePage from "./HomePageLayout";
import Privacy from './Privacy';
import Terms from './Terms';
import Contact from './Contact';
import About from './About';
import Header from './Header';
import Footer from './Footer';
import Blog from './Blog';

const Styles = styled.div`

`

class Main extends Component {
  
render() {
    return (                         
            <>
                <Header/>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/blog" component={Blog} />
                    </Switch>
                   <div style={{flexGrow:"1", backgroundColor:"#013153"}}>&nbsp;</div>        
                <Footer/>
                <div style={{flexGrow:"1", backgroundColor:"#013153"}}>&nbsp;</div>        
            </> )
}

}

export default withRouter(Main);