import React from 'react';
import styled from 'styled-components'
import Header from './Header';

const Styles = styled.div`
    padding:0px
`

function Privacy(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
return (
    <Styles>
        
        <Header/>
        
		<section class="content" id="legal">
		
        	<div class="container">
                            
                <h1>Privacy Policy</h1>
				<p>This Privacy Policy describes Inventory Shark's practices regarding the collection, and the use and disclosure of the information we collect from and about you when you use Inventory Shark's web-based application (the "Service"). By accessing or using the Service, you agree to this Privacy Policy and our End User License Agreement and Terms of Service.</p>
				
				<h4>Our Collection and Use of Information You Provide to Us</h4>				
				
				<p>In order to set up a user account for use of the Service, we collect personal information, such as your name, address, phone number, email address, payment information, 
                    and Amazon account seller information when you register for an account. Inventory Shark maintains this information indefinitely for record purposes.</p>
				
				<p>If you are a user of our paid Service, we will utilize a third party credit card payment processing company to collect payment information, including your credit card number, billing address, and phone number. 
                    We will share this payment information with the third party processing company as detailed below in "How We Share Your Information: With Trusted Service Providers And Business Partners." 
                    We do not store your payment information.</p>
				
				<p>We may use your email address to send you Service-related notices.</p>
								
				<h4>Your Content</h4>

				<p>Your use of the Service will involve you uploading or inputting various content into the Service; including but not limited to: tasks, attachments, project names, team names, and conversations (together, the "Content").</p>

				<p>You control how your Content is shared with others via your settings on the Service. 
                    Inventory Shark may view your Content only as necessary (i) to maintain, provide and improve the Service; (ii) to resolve a support request from you; (iii) if we have a good faith belief, or have received a complaint alleging, that such Content is in violation of our Acceptable Use Guidelines; (iv) as reasonably necessary to allow Inventory Shark to comply with or avoid the violation of applicable law or regulation; or (v) to comply with a valid legal subpoena or request that meets the requirements of our Law Enforcement Guidelines. 
                    We may also analyze the Content in aggregate and on an anonymized basis, in order to better understand the manner in which our Service is being used.			
                </p>
				
				<h4>Information We Collect Automatically</h4>

				<p>We use technologies like cookies to improve the Service. For example, a cookie is used to remember your user name when you return to the Service and to improve our understanding of how you interact with the Service. You can block cookies on your web browser; however, please be aware that some features of the Service may not function properly if the ability to accept cookies is disabled. While we collect this information automatically, at no time is your personal information ever used to make automated decisions for the Service provided or the Company as a whole.</p>
				
				<h4>Log Files</h4>

				<p>When you use the Service, our servers automatically record certain information in server logs. These server logs may include information such as your web request, Internet Protocol ("IP") address, browser type, referring/exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, mobile carrier, and other such information. Log files help us to monitor, analyze, improve, and maintain the Service and to diagnose and fix any Service-related issues.</p>
							
				<h4>How We Share Your Information</h4>

				<p>We may share the information we collect from you with third parties as detailed below.</p>
				
				<h4>As Directed By You</h4>

				<p>We will display your Profile information on your profile page and elsewhere on the Service in accordance with the preferences you set in your account. You can review and revise your Profile information at any time.</p>
				
				<h4>We Will Display Your Content Within The Service As Directed By You</h4>

				<p>If you elect to give access to a third-party application, then we may share or disclose your account and Profile information and your Content with that third-party application as directed by you. Please remember that we are not responsible for the privacy practices of such third parties, so you should make sure you trust the application and that it has a privacy policy acceptable to you.</p>
				
				<h4>With Trusted Service Providers and Business Partners</h4>

				<p>We may utilize trusted third party service providers to assist us in delivering our Service. For example, we may use third parties to help host our Service, send out email updates, or process payments. These service providers may have access to your information for the limited purpose of providing the service we have contracted with them to provide. They are required to have a privacy policy and security standards in place that are at least as protective of your information as is this Privacy Policy. 
                    
                    We may also store personal information in locations outside the direct control of Inventory Shark (for instance, on servers or databases co-located with hosting providers).</p>

				<p>The US-based third-party service providers utilized by Inventory Shark are as follows:</p>
				<ul>
					<li><a href="https://aws.amazon.com/compliance/data-privacy-faq/" target="_blank">Amazon Web Services</a> - cloud services provider</li>
					<li><a href="https://www.avalara.com/" target="_blank">Avalara</a> - Tax calculation partnet</li>
                    <li><a href="https://www.quickbooks.com/" target="_blank">Quickbooks</a> - Financial </li>
				</ul>
				
				<h4>With Law Enforcement or In Order to Protect Our Rights</h4>

				<p>We may disclose your information (including your personally identifiable information) if required to do so by law or subpoena and if the relevant request meets our Law Enforcement Guidelines. We may also disclose your information to our legal counsel, governmental authorities, or law enforcement if we believe that it is reasonably necessary to do so in order to comply with a law or regulation; to protect the safety of any person; to address fraud, security or technical issues; or to protect Inventory Shark's rights or property.</p>
				
				<h4>In an Aggregate and Non-Personally Identifiable Manner</h4>
				
				<p>We may disclose aggregate non-personally identifiable information (such as aggregate and anonymous usage data, platform types, etc.) about the overall use of our Service publicly or with interested third parties to help them understand, or to help us improve, the Service.</p>

				<h4>In Connection With a Sale or Change of Control</h4>

				<p>If the ownership of all or substantially all of our business changes, we may transfer your information to the new owner so that the Service can continue to operate. In such case, your information would remain subject to the promises and commitments contained in this Privacy Policy until such time as this Privacy Policy is updated or amended by the acquiring party upon notice to you.</p>

				<h4>Specific Sensitive Information</h4>

				<p>Inventory Shark does not collect or process the following sensitive personal information at any time for any reason: Social Security number, gender, race or ethnic origin, political opinions, religious or philosophical beliefs, trade union memberships, genetic or biometric data, health or mortality, sex life or sexual orientation.</p>
				
				<h4>How We Protect Your Information</h4>

				<p>The security of your information is important to us. When you enter sensitive information (such as a credit card number) as part of our service, we encrypt the transmission of that information using industry-standard encryption.</p>

				<p>Inventory Shark uses commercially reasonable and industry-standard physical, managerial, and technical safeguards to preserve the integrity and security of your information. For example, we continuously and regularly back up your data to help prevent data loss and aid in data recovery. We also guard against common web attack vectors, host data in secure SAS 70 audited data centers, and implement firewalls and access restrictions on our servers to secure our network and better protect your information.</p>
																
				<h4>Changes to Our Privacy Policy</h4>

				<p>If we change our Privacy Policy, we will post those changes on this page to keep you aware of what information we collect, how we use it, and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                
                <div style={{height:"100px"}}>&nbsp;</div>

        </div>
    </section>


    </Styles>
)


}

export default Privacy
