import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  textField: {
    marginTop: '0px',
    marginLeft: '0px',
    marginBottom: '0px',
    width:"400px"
  },
  label: {
    '&$focused': {
      color: '#4A90E2'
    },
  },
  focused: {},
  outlinedInput: {
    '&$focused $notchedOutline': {
      border: '1px solid #4A90E2'
    },
  },
  input: {
    marginTop:"3px"
  }
  // root:{    'label + &': {
  //   marginTop: "0px"
  // },
// }
})

const CustomTextField = ({classes, properties, defaultValue, onChange}) => (

<>
<form style={{display:"inline-block"}} className={classes.root} noValidate autoComplete="off">
  <TextField
    id="outlined-basic"
    label={properties.name}
    name={properties.name}
    className={classes.textField}
    value={defaultValue}
    // type="search"
    // autoComplete="search"
    multiline
    rows={properties.rows}
    rowsMax={10}
    margin="normal"
    variant="standard" //outlined, standard
    onChange= {            
        e =>
        { 
            onChange(e);
        }
    }
    InputLabelProps={{
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    }}
    InputProps={{
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
      },
    autoComplete: 'off',
    error:properties.error ? properties.error : false
    }
}
  />
  </form>
  </>
)

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CustomTextField)